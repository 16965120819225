footer.content-info {
  background-color: #252525;
  color: #fff;
  padding: 30px 0;
  .myriad-pro();
  
  a {
    color: #fff;
    
    &:hover {
      color: @brand-primary;
    }
  }
  
  .row.cols {
    section.widget_nav_menu {
      ul.menu {
        .list-unstyled();
        
        li {
          margin-bottom: 10px;
          
          a {
            display: block;
            font-size: 18px;
          }
        }
      }
    }
    
    section.widget_text {
      .textwidget {
        font-size: 14px;
        
        .nerdpress-social-networks {
          ul.list-inline {
            li {
              padding: 0;

              &.phone {
                display: none;
              }
              
              a {
                color: #7c7c7c;
                font-size: 36px;
                transition: all .3s ease;
                
                &:hover {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  
  .row.colophon {
    font-size: 14px;
  }
}