* {
  -webkit-font-smoothing: antialiased;
  .user-select(@brand-primary);
}

.content {
  img.size-full {
    .img-responsive();
  }
}

.myriad-pro {
  font-family: "myriad-pro", sans-serif;
}

h1 {
  font-weight: 900;
}

h2 {
  font-weight: 600;
}

.page-header {
  border-bottom-color: transparent;
  margin-bottom: 0;
  padding-bottom: 0;

  h1 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .navbar-right {
    .dropdown-menu {
      right: auto;
    }
  }
}
