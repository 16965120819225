.modules {
  margin: 20px auto;
  padding: 40px 0;

  &.call_to_action {
    @media ( max-width: 767px ) {
      a.btn {
        .btn-block();
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
  }

  &.forward_paths {
    padding: 40px 0 20px;

    .mod-col {
      .overlay-wrap {
        background-color: #f5f5f5;
        background-position: center top;
        background-size: cover;
        height: 485px;
        position: relative;

        .overlay {
          background-color: #252525;
          background-color: rgba(37, 37, 37, 0.749);
          bottom: 0;
          color: #fff;
          position: absolute;
          width: 100%;

          .overlay-inner {
            padding: 20px;

            h3 {
              font-size: 28px;
            }

            .path-content {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  &.hero {
    background-position: center top;
    background-size: cover;
    padding: 0;
    position: relative;

    .mod-header {
      background-color: #000000;
      background-color: rgba(0, 0, 0, .59);
      color: #fff;
      padding: 20px 0;
      position: absolute;
      bottom: 0;
      width: 100%;

      h1 {
        margin: 0;
      }
    }
  }

  &.image_gallery {
    .mod-col {
      a {
        color: #fff;

        .overlay-wrap {
          position: relative;

          .overlay {
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
        }
      }
    }
  }

  &.logo_bar {
    .row.logos {
      margin-top: 50px;

      .logo-wrap {
        height: 100%;
        position: relative;

        .logo-inner {
          position: absolute;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &.tiles {
    .mod-col {
      margin-bottom: 30px;

      a {
        text-decoration: none;

        .tile-wrap {
          background-size: cover;
          height: 285px;
          position: relative;

          .overlay {
            background-color: @text-color;
            background-color: rgba(37,37,37,.749);
            padding: 20px 0;
            position: absolute;
            bottom: 20px;
            width: 100%;
            transition: all .3s ease;

            h3 {
              color: #fff;
              font-size: 28px;
              font-weight: 600;
              margin: 0;
            }
          }
        }

        &:hover {
          .tile-wrap {
            .overlay {
              background-color: rgba(140, 34, 38, .749);
            }
          }
        }
      }
    }
  }
}
