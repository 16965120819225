#masthead {
  background-color: #fafafa;
  
  #hdr-contact {
    background-color: #252525;
    
    .container {
      .row {
        .col-xs-12 {
          .nerdpress-social-networks {
            ul.list-inline {
              margin-bottom: 0;
              
              li {
                padding: 0;

                a {
                  color: #7c7c7c;
                  
                  &:hover {
                    color: #fff;
                  }
                }
                
                &.phone {
                  background-color: #3b3b3b;
                  padding: 5px 20px;
                  
                  a {
                    color: #fff;
                    font: 24px "myriad-pro";
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  #hdr-main {
    padding-top: 10px;
  }
  
  header.banner.navbar-default.navbar-static-top {
    margin-bottom: 0;
    
    .navbar-nav {
      li {
        a {
          font-size: 16px;
          font-weight: 600;
          
          .caret {
            border-top-color: @brand-primary;
            color: @brand-primary;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  #masthead {
    header.banner.navbar-default.navbar-static-top {
      margin-top: 35px;
    }
  }
}