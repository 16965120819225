// Grid system
.main {
  .make-sm-column(@main-sm-columns);
  .sidebar-primary & {
    .make-sm-column(@main-sm-columns - @sidebar-sm-columns);
  }
}
.sidebar {
  .make-sm-column(@sidebar-sm-columns);
}
